<template>
        <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 420 127" style="enable-background:new 0 0 420 127;" xml:space="preserve">
            <g>
	            <g>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M136,62.3c1.7-1.7,3.7-3,5.9-4c2.1-0.9,4.5-1.3,7.3-1.3h4.3c0.8,0,1.5-0.7,1.5-1.5V48c0-0.8-0.7-1.5-1.5-1.5
                h-4.3c-4.2,0-7.9,0.7-11.3,2.1c-3.6,1.5-6.7,3.6-9.4,6.2c-2.7,2.6-4.8,5.7-6.3,9.2c-1.5,3.5-2.3,7.3-2.3,11.3v27.5
                c0,0.8,0.7,1.5,1.5,1.5h7.5c0.8,0,1.5-0.7,1.5-1.5V75.4c0-2.5,0.5-5,1.5-7.2C132.6,66.2,134,64.3,136,62.3z"/>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M286.2,98.7c4.3,3.4,10.9,5.3,19.7,5.6c0.8,0,1.5-0.6,1.5-1.5V96c0-0.8-0.6-1.4-1.4-1.5
                c-1.7-0.1-3.1-0.2-4.4-0.4c-1.8-0.3-3.6-0.8-5.6-1.7c-2.1-0.9-3.6-2.4-4.7-4.6c-1-2.1-1.6-4.7-1.6-7.9v-23v-0.6h0.6h15.5
                c0.8,0,1.5-0.7,1.5-1.5v-6.8c0-0.8-0.7-1.5-1.5-1.5h-15.5h-0.6v-0.6V29.9c0-0.8-0.7-1.5-1.5-1.5h-7.5c-0.8,0-1.5,0.7-1.5,1.5v27.2
                v23C279.3,88.7,281.6,95,286.2,98.7z"/>
                <g>
                    <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M322.9,104.3h7.3c0.8,0,1.5-0.7,1.5-1.5V48.2c0-0.8-0.7-1.5-1.5-1.5h-16c-0.8,0-1.5,0.7-1.5,1.5V55
                    c0,0.8,0.7,1.5,1.5,1.5h6.6h0.6v0.6v45.7C321.5,103.6,322.1,104.3,322.9,104.3z"/>
                    <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M330,39.3c-1.2,1.2-2.7,1.9-4.5,1.9c-1.8,0-3.3-0.6-4.5-1.9c-1.2-1.2-1.9-2.7-1.9-4.5s0.6-3.3,1.9-4.5
                    c1.2-1.2,2.7-1.9,4.5-1.9c1.8,0,3.3,0.6,4.5,1.9c1.2,1.2,1.9,2.7,1.9,4.5S331.3,38,330,39.3z"/>
                </g>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M363.5,102.8V96c0-0.8-0.6-1.4-1.4-1.5c-1.5-0.1-2.9-0.2-3.9-0.4c-1.5-0.3-3.1-0.8-4.8-1.7
                c-1.7-0.9-3-2.4-3.8-4.5c-0.8-2-1.2-4.7-1.2-7.9V29.9c0-0.8-0.7-1.5-1.5-1.5h-7.5c-0.8,0-1.5,0.7-1.5,1.5v50.2
                c0,8.8,2,15.1,5.9,18.7c3.6,3.3,9.7,5.2,18.1,5.4C362.9,104.3,363.5,103.6,363.5,102.8z"/>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M230.7,62.5c1.7-1.7,3.7-3,5.9-4c2.2-1,4.7-1.4,7.3-1.4c2.6,0,5,0.5,7.3,1.4c2.3,1,4.3,2.3,6,4
                c1.7,1.7,3,3.7,4,5.9c1.1,2.5,1.6,4.8,1.6,7.2v27.3c0,0.8,0.7,1.5,1.5,1.5h7.5c0.8,0,1.5-0.7,1.5-1.5V75.5c0-4-0.8-7.8-2.3-11.3
                c-1.5-3.5-3.6-6.6-6.3-9.2c-2.7-2.6-5.8-4.7-9.3-6.2c-3.5-1.5-7.3-2.3-11.4-2.3c-4,0-7.8,0.8-11.4,2.3c-3.6,1.5-6.7,3.6-9.3,6.2
                c-2.6,2.6-4.8,5.7-6.3,9.2c-1.5,3.5-2.3,7.3-2.3,11.3v27.3c0,0.8,0.7,1.5,1.5,1.5h7.5c0.8,0,1.5-0.7,1.5-1.5V75.5
                c0-2.4,0.5-4.7,1.6-7.2C227.6,66.1,229,64.1,230.7,62.5z"/>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M176.1,91.8l-0.8-0.4l0.7-0.6L209.3,61c0.6-0.5,0.7-1.5,0.1-2.1l-2.5-2.7c-2.7-3-5.9-5.3-9.4-6.9
                c-3.5-1.6-7.2-2.5-10.9-2.7c-0.5,0-1,0-1.5,0c-3.2,0-6.4,0.5-9.6,1.6c-3.7,1.2-7,3.2-10,5.9c-3,2.7-5.3,5.8-6.9,9.2
                c-1.6,3.4-2.5,7.1-2.6,10.7c-0.2,3.7,0.3,7.4,1.5,11c1.2,3.6,3.2,7,5.9,9.9c2.6,2.9,5.7,5.2,9.2,6.8c3.2,1.5,6.9,2.4,11.1,2.6
                c0.5,0,1,0,1.5,0c3.3,0,6.6-0.6,9.8-1.7c3.9-1.4,7.1-3.2,9.8-5.7l2.8-2.5c0.6-0.5,0.7-1.5,0.1-2.1l-5.1-5.6
                c-0.5-0.6-1.5-0.6-2.1-0.1l-2.8,2.5c-3,2.7-6.5,4.2-10.5,4.6c-0.8,0.1-1.5,0.1-2.3,0.1C181.6,94,178.8,93.3,176.1,91.8z
                 M170.5,81.5l-1.6,1.4l-0.7,0.6l-0.4-0.8c-1.2-2.6-1.7-5.4-1.5-8.2c0.3-5.1,2.3-9.4,6.2-12.8c3.1-2.8,6.6-4.3,10.6-4.7
                c0.6-0.1,1.2-0.1,1.8-0.1c3.3,0,6.4,0.8,9.2,2.4l0.8,0.4l-0.7,0.6c-1.3,1.2-3.2,2.8-5.5,4.9c-2.4,2.1-4.8,4.3-7.4,6.6l-7.3,6.5
                L170.5,81.5z"/>
                <path :class="colorMode == 'dark' ? 'st0-dark' : 'st0'" d="M416.8,64.2c-1.5-3.5-3.5-6.5-6.1-9.1c-2.6-2.6-5.6-4.6-9-6.1c-3.4-1.5-7.1-2.3-11.1-2.3
                c-3.9,0-7.6,0.8-11,2.3c-3.5,1.5-6.5,3.3-9.1,6c-1.1,1.2-2.2,2.4-3.1,3.7c-1.2,1.7-2.2,3.5-3.1,5.5c-1.5,3.5-2.2,7.2-2.2,11.3
                c0,0,0,0,0,0s0,0,0,0c0,4,0.7,7.8,2.2,11.3c0.8,2,1.9,3.8,3.1,5.5c0.9,1.3,1.9,2.6,3.1,3.7c2.6,2.7,5.7,4.5,9.1,6
                c3.5,1.5,7.1,2.3,11,2.3c4,0,7.7-0.8,11.1-2.3c2.7-1.2,5.2-2.7,7.4-4.6v5.4c0,0.8,0.7,1.5,1.5,1.5h7c0.8,0,1.5-0.7,1.5-1.5V75.5
                C419,71.5,418.2,67.7,416.8,64.2z M403.6,88.7c-1.7,1.7-3.6,3-5.9,4c-1.6,0.7-3.3,1.1-5.1,1.3h-2.1c-2.5,0-4.9-0.5-7.2-1.5
                c-1-0.4-1.9-0.9-2.8-1.4c-1.1-0.7-2.2-1.6-3.1-2.5c-1.7-1.7-3-3.7-4-5.9c-0.9-2.2-1.4-4.6-1.4-7.1c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2
                c0-2.5,0.5-4.9,1.4-7.1c1-2.3,2.3-4.2,4-5.9c0.9-1,2-1.8,3.1-2.5c0.9-0.5,1.8-1,2.8-1.4c2.3-1,4.7-1.5,7.2-1.5h2.1
                c1.8,0.2,3.5,0.6,5.1,1.3c2.2,1,4.2,2.3,5.9,4c1.7,1.7,3,3.7,4,5.9c1,2.3,1.6,4.7,1.6,7.3v0c0,2.6-0.6,5-1.6,7.3
                C406.6,85,405.3,87,403.6,88.7z"/>
            </g>
                <path class="st1" d="M102.7,99.5V48.4c0-2.5-1.1-4.9-2.9-6.5L54.7,1.1c-1.7-1.5-4.2-1.5-5.9,0L2.9,41.9C1.1,43.6,0,46,0,48.5v73.9
                c0,2.4,2,4.4,4.4,4.4h70.9c2.4,0,4.4-2,4.4-4.4v-18.5h18.6C100.7,103.9,102.7,102,102.7,99.5z M79.7,73.5
                c-0.1-3.6-0.9-7.1-2.4-10.5c-1.4-3.3-3.6-6.4-6.4-9.3c-2.6-2.6-5.8-4.8-9.3-6.3c-3.5-1.5-7.3-2.3-11.4-2.3c-4,0-7.8,0.8-11.4,2.3
                c-3.6,1.5-6.7,3.6-9.4,6.3c-2.7,2.6-4.8,5.8-6.3,9.3c-1.5,3.5-2.3,7.3-2.3,11.4c0,4,0.8,7.8,2.3,11.4c1.5,3.6,3.7,6.7,6.3,9.4
                c2.7,2.7,5.9,4.8,9.4,6.3c3.5,1.5,7.4,2.3,11.4,2.3h0.4l19.1,0.1l1,0v1v11.8v1h-1H10.1H9v-1V49.3v-0.5l0.3-0.3L51,10.9l0.7-0.6
                l0.7,0.6l40.9,37.3l0.3,0.3v0.5v45v1h-1H80.8h-1v-1V74.4v-0.6L79.7,73.5z M70.5,74.4v19.5v1l-1,0l-19.3,0c-2.8,0-5.5-0.5-8-1.6
                c-2.5-1.1-4.7-2.5-6.6-4.4c-1.9-1.9-3.3-4.1-4.4-6.6c-1.1-2.5-1.6-5.2-1.6-8c0-2.8,0.5-5.5,1.6-7.9c1.1-2.4,2.5-4.6,4.4-6.5
                c1.9-1.9,4.1-3.3,6.6-4.4c2.5-1.1,5.2-1.6,8-1.6c2.8,0,5.5,0.5,7.9,1.6c2.4,1.1,4.6,2.5,6.5,4.4c1.9,1.9,3.3,4,4.4,6.5
                C70,68.9,70.5,71.6,70.5,74.4z"/>
            </g>
        </svg> -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 238 48" style="enable-background:new 0 0 238 48;" xml:space="preserve">

    <path class="st0" d="M59.1,14.1h3.7V35h-3.7V14.1z M67.1,14.1h5.7l5.5,14.6l5.6-14.6h5.6V35H86V17.6h-0.1L79.6,35H77l-6.4-17.4h-0.1
V35H67L67.1,14.1z M94,14.1h5.7l5.6,14.6l5.6-14.6h5.6V35h-3.5V17.6h-0.1L106.4,35h-2.6l-6.3-17.4h-0.1V35h-3.5L94,14.1z
 M119.6,24.6c0-1.6,0.3-3.1,0.8-4.6c0.5-1.3,1.3-2.5,2.3-3.5c1-1,2.2-1.7,3.5-2.2c1.4-0.5,2.9-0.8,4.4-0.8c1.5,0,3,0.2,4.5,0.7
c1.3,0.5,2.5,1.2,3.5,2.2c1,1,1.8,2.2,2.3,3.5c0.6,1.4,0.8,3,0.8,4.5c0,1.5-0.3,3-0.8,4.5c-0.5,1.3-1.3,2.5-2.3,3.5
c-1,1-2.2,1.8-3.5,2.3c-1.4,0.6-2.9,0.8-4.5,0.8c-1.5,0-3-0.2-4.4-0.8c-1.3-0.5-2.5-1.2-3.5-2.2c-1-1-1.8-2.1-2.3-3.4
C119.8,27.6,119.5,26.1,119.6,24.6z M123.5,24.4c0,1.1,0.2,2.1,0.5,3.1c0.3,0.9,0.8,1.7,1.5,2.5c0.6,0.7,1.4,1.2,2.3,1.6
c0.9,0.4,1.9,0.6,2.9,0.6c1,0,2-0.2,3-0.6c0.9-0.4,1.6-0.9,2.3-1.6c0.6-0.7,1.1-1.5,1.4-2.5c0.4-1,0.5-2,0.5-3.1c0-1-0.2-2-0.5-2.9
c-0.3-0.9-0.8-1.7-1.4-2.4c-0.6-0.7-1.4-1.2-2.3-1.6c-0.9-0.4-1.9-0.6-3-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.6,0.9-2.3,1.6
c-0.6,0.7-1.1,1.5-1.4,2.4C123.6,22.4,123.5,23.4,123.5,24.4z M149.1,17.4h-6.4v-3.4h16.5v3.4h-6.4V35h-3.7V17.4z M160.2,24.6
c0-1.6,0.3-3.1,0.8-4.6c0.5-1.3,1.3-2.5,2.3-3.5c1-1,2.2-1.7,3.5-2.2c1.4-0.5,2.9-0.8,4.4-0.8c1.5,0,3,0.2,4.5,0.7
c1.3,0.5,2.5,1.2,3.5,2.2c1,1,1.8,2.2,2.3,3.5c0.6,1.4,0.9,3,0.8,4.5c0,1.5-0.3,3-0.8,4.4c-0.5,1.3-1.3,2.5-2.3,3.5
c-1,1-2.2,1.8-3.5,2.3c-1.4,0.6-2.9,0.8-4.5,0.8c-1.5,0-3-0.2-4.4-0.8c-1.3-0.5-2.5-1.2-3.5-2.2c-1-1-1.8-2.2-2.3-3.5
C160.4,27.6,160.2,26.1,160.2,24.6z M164.1,24.4c0,1.1,0.2,2.1,0.5,3.1c0.3,0.9,0.8,1.7,1.5,2.5c0.6,0.7,1.4,1.2,2.2,1.6
c0.9,0.4,1.9,0.6,2.9,0.6s2-0.2,2.9-0.6c0.9-0.4,1.6-0.9,2.3-1.6c0.6-0.7,1.1-1.5,1.5-2.5c0.4-1,0.5-2,0.5-3.1c0-1-0.2-2-0.5-2.9
c-0.3-0.9-0.8-1.7-1.5-2.4c-0.6-0.7-1.4-1.2-2.3-1.6c-1.9-0.8-4-0.8-5.9,0c-0.9,0.4-1.6,0.9-2.2,1.6c-0.6,0.7-1.1,1.5-1.5,2.4
C164.2,22.4,164.1,23.4,164.1,24.4z M185.4,14.1h7c1,0,1.9,0.1,2.9,0.3c0.9,0.2,1.7,0.5,2.5,1c0.7,0.5,1.3,1.1,1.7,1.9
c0.4,0.9,0.7,1.9,0.6,2.9c0.1,1.1-0.2,2.2-0.7,3.2c-0.4,0.8-1.1,1.4-1.8,1.9c-0.8,0.5-1.7,0.8-2.7,0.9c-1,0.2-2.1,0.2-3.2,0.2h-2.6
V35h-3.7L185.4,14.1z M191.4,23.1c0.5,0,1.1,0,1.6-0.1c0.5,0,1.1-0.2,1.6-0.4c0.5-0.2,0.9-0.5,1.2-0.9c0.3-0.5,0.5-1.1,0.5-1.7
c0-0.5-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-0.9-0.4-1.4-0.4c-0.5-0.1-1-0.1-1.5-0.1h-2.7v5.9H191.4z M197.8,32.8
c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.2,0.2,1.7,0.6
c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5
c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7
C197.8,33.5,197.7,33.2,197.8,32.8L197.8,32.8z M206.4,14h3.7v17.6h8.9V35h-12.6L206.4,14z M238,27.2c0,1.2-0.2,2.3-0.6,3.4
c-0.4,1-1,1.9-1.8,2.6c-0.8,0.7-1.7,1.3-2.6,1.6c-2.2,0.8-4.5,0.8-6.7,0c-1-0.4-1.9-0.9-2.7-1.6c-0.8-0.7-1.4-1.6-1.8-2.6
c-0.4-1.1-0.6-2.2-0.6-3.4V14.1h3.7v13.1c0,0.5,0.1,1.1,0.2,1.6c0.2,0.6,0.4,1.1,0.8,1.5c0.4,0.5,0.9,0.9,1.4,1.2
c0.7,0.3,1.5,0.5,2.2,0.5s1.5-0.2,2.2-0.5c0.6-0.3,1.1-0.7,1.4-1.2c0.4-0.5,0.6-1,0.8-1.5c0.2-0.5,0.2-1.1,0.2-1.6V14.1h3.7V27.2z"
    />
    <path class="st1" d="M5.5,2.5c-0.8,0-1.5,0.3-2.1,0.9C2.8,3.9,2.5,4.7,2.5,5.5v37.1c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9
h37.1c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1V5.5c0-0.8-0.3-1.5-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9H5.5z M5.5,0h37.1
c1.5,0,2.8,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.9v37.1c0,1.5-0.6,2.8-1.6,3.9c-1,1-2.4,1.6-3.9,1.6H5.5c-1.5,0-2.8-0.6-3.9-1.6
c-1-1-1.6-2.4-1.6-3.9V5.5C0,4,0.6,2.6,1.6,1.6C2.6,0.6,4,0,5.5,0z"/>
    <path class="st1" d="M33.7,34.1c0.5,0.6,1.2,1.1,1.9,1.2c0.8,0.2,1.6,0,2.3-0.4c0.6-0.5,1.1-1.2,1.2-1.9c0.2-0.8,0-1.6-0.4-2.3
L26.5,13.9c-0.3-0.4-0.6-0.7-1-0.9c-0.4-0.2-0.9-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-1,0.9L9.7,30.7
c-0.4,0.7-0.5,1.5-0.4,2.3s0.6,1.5,1.2,2c0.7,0.4,1.5,0.6,2.3,0.4c0.8-0.2,1.5-0.6,1.9-1.2l9.7-13.1L33.7,34.1z"/>
</svg>

</template>

<style type="text/css">
    .st0{fill:#1D1E1C;}
    .st0-dark{fill:#FEFEFE;}
    .st1{fill:#83A833;}
</style>

<!--<style type="text/css">-->
<!--.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#2B6482;}-->
<!--.st1{fill:#2B6482;}-->
<!--</style>-->

<script>
import store from "@/store";

export default {
    name: 'Logo',
    computed: {
        colorMode(){
            return store.state.appConfig.layout.skin;
        },
    },
};


</script>
